import React from "react"
import "../assets/css/main.scss"
import Layout from "../components/layout"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div></div>
    </Layout>
  )
}

export default IndexPage
